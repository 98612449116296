import Head from 'next/head';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDayjs';
import '../src/styles/globals.css';
import { CssBaseline } from '@mui/material';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import CookiesNotification from 'src/components/CookiesNotification';
import { createTheme } from '../src/theme';
import { createEmotionCache } from '../src/utils/create-emotion-cache';

const clientSideEmotionCache = createEmotionCache();

function App({ Component, emotionCache = clientSideEmotionCache, pageProps }) {
  return (
    <>
      <Head>
        <title>Skill Exchange | Democratising Learning</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>
      <CacheProvider value={emotionCache}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={createTheme({
              direction: 'ltr',
              responsiveFontSizes: true,
              theme: 'SIMPLE',
            })}
          >
            <LocalizationProvider dateAdapter={DateAdapter}>
              <CssBaseline />
              <Component {...pageProps} />
              {/* <CookiesNotification /> */}
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </>
  );
}

export default App;
